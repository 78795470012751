import { IntlFormatters, useIntl as useReactIntl } from "react-intl";
import { translationKeys } from "./translationMap";

type FormatMessageArgs = Parameters<IntlFormatters["formatMessage"]>;

export function useIntl() {
  // Pull out the original formatMessage function.
  const { formatMessage, ...rest } = useReactIntl();

  // Re-write the formatMessage function but with a strongly-typed id.
  const typedFormatMessage = (
    descriptor: FormatMessageArgs[0] & {
      id?: translationKeys;
    },
    values?: FormatMessageArgs[1],
    options?: FormatMessageArgs[2]
  ) => {
    return formatMessage(
      descriptor,
      values as Record<string, any> | undefined,
      options
    );
  };

  return {
    ...rest,
    formatMessage: typedFormatMessage,
  };
}
